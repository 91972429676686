import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import "./App.scss";
import AirdropContainer from "./components/airdrop-container/AirdropContainer";
import BuyGuideScroll from "./components/buy-guide/BuyGuideScroll";
import FaQContainer from "./components/faq/FaQContainer";
import Footer from "./components/footer/Footer";
import { numberWithCommas } from "./components/util";
import { backendApiUrl } from "./const";

function App() {
  const { user } = useAuth0();
  const [totalMention, setTotalMention] = useState(0);
  useEffect(() => {
    if (user?.sub) {
      document.getElementsByClassName("App")[0].scrollTo({
        top: document
          .getElementsByClassName("how-to-receive")[0]
          .getBoundingClientRect().top,
      });
    }
  }, [user?.sub]);

  useEffect(() => {
    axios.get(`${backendApiUrl}/mention`).then((res) => {
      setTotalMention(res.data);
    });
  }, []);

  return (
    <div className="App">
      <div className="sky-background" />
      <span className="app-name">SKYDOGE</span>
      <span className="app-subtitle">
        Reply to Elon’s tweets,
        <br />
        earn instant SKYDOGE airdrops
      </span>
      <button
        className="buy-button"
        onClick={() =>
          window.open(
            "https://app.uniswap.org/#/swap?exactField=output&inputCurrency=ETH&outputCurrency=0x3c00f3E5A1cB06CcA9eC0fb8B36Af2DAaF54fBBb"
          )
        }
      >
        Buy $SKYDOGE
      </button>
      <i className="doge-main-icon" />
      <i className="cloud-top-icon" />
      <div className="cloud-background">
        <div className="number-of-mention">
          <span className="title">Total Mentions of SKYDOGE</span>
          <span className="number">
            {numberWithCommas(String(totalMention).padStart(6, "0"))}
          </span>
        </div>
        <span className="how-to-receive">
          HOW TO GET
          <br />
          $SKYDOGE AIRDROP?
        </span>
        <span className="guide-text">
          Connect your twitter account,
          <br />
          paste to reply on Elon’s tweets.
        </span>
        <AirdropContainer />
      </div>
      <i className="cloud-bottom-icon" />
      <span className="buy-guide">BUY GUIDE</span>
      <BuyGuideScroll />
      <FaQContainer />
      <Footer />
    </div>
  );
}

export default App;
