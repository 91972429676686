import "./PasteReferralText.scss";

interface Props {
  proceedNextStep: () => void;
}

const PasteReferralText = ({ proceedNextStep }: Props) => {
  return (
    <div className="paste-referral-text airdrop-content">
      <span className="step">Step 04</span>
      <span className="title">
        Paste referral text to any
        <br />
        of Elon’s tweets.
      </span>
      <div className="tips">
        <span className="tip-title">Tips for more airdrops</span>
        <span className="tip-content">The more replies, the more airdrop!</span>
      </div>
      <span
        className="go-elon-link"
        onClick={() => window.open("https://www.twitter.com/elonmusk")}
      >
        Go Elon's Tweet
      </span>
      <button className="submit-button" onClick={proceedNextStep}>
        Check my $SKYDOGE
      </button>
    </div>
  );
};

export default PasteReferralText;
