import "./BuyGuideStep.scss";

interface Props {
  icon: string;
  step: number;
  description: string;
  buttonInfo?: {
    title: string;
    onClick: () => void;
  };
}

const BuyGuideStep = ({ icon, step, description, buttonInfo }: Props) => {
  return (
    <div className="buy-guide-step">
      <i className={`icon ${icon}`} />
      <span className="step">Step 0{step}</span>
      <span className="description">{description}</span>
      {buttonInfo && (
        <button onClick={buttonInfo.onClick}>{buttonInfo.title}</button>
      )}
    </div>
  );
};

export default BuyGuideStep;
