import { useAuth0 } from "@auth0/auth0-react";
import "./ConnectTwitter.scss";

const ConnectTwitter = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="connect-twitter airdrop-content">
      <span className="step">Step 01</span>
      <span className="title"> Connect your twitter account</span>
      <div className="twitter-circle">
        <i className="twitter-icon" />
      </div>
      <button className="submit-button" onClick={loginWithRedirect}>
        Connect Twitter
      </button>
    </div>
  );
};

export default ConnectTwitter;
