import FaQ from "./FaQ";
import "./FaQContainer.scss";

const FaQContainer = () => {
  return (
    <div className="faq-container">
      <span className="faq-header">FAQ</span>
      <FaQ
        title={"What is R2E"}
        content={
          "Reply-to-Earn. SKYDOGE is the first R2E in the world that encourages social communication. The more replies, the more airdrops."
        }
      />
      <FaQ
        title={"How many replies can i post per day?"}
        content={
          "You can post a reply every 10 minutes, more than two replies within 10 minutes will count as one."
        }
      />
      <FaQ
        title={"How to fix insufficient funds on Uniswap?"}
        content={"Deposit ETH after following our Buy Guide."}
      />
      <FaQ
        title={"What is SKYDOGE($SKYDOGE)?"}
        content={
          "SKYDOGE is a R2E token designed to incentivize social network participation and communication between influencer and followers."
        }
      />
      <FaQ
        title={"Which chain is SKYDOGE available on?"}
        content={"SKYDOGE is currently available on the Ethereum mainnet."}
      />
      <FaQ
        title={"What are the fees?"}
        content={
          "Every transaction has a 0.3% fee imposed by Uniswap, no fee is imposed by the SKYDOGE team."
        }
      />
      <FaQ
        title={"Why do we have to login with Twitter?"}
        content={
          "Other users may abuse R2E and link your twitter account with their wallet address. To prevent this, we add twitter login so you can safely receive your airdrops for your replies."
        }
      />
      <FaQ
        title={"Why do we have to connect our wallets?"}
        content={
          "You don’t have to connect your wallet to participate in R2E, but you have to if you want to claim your airdrop."
        }
      />
      <FaQ
        title={"Do I get more $SKYDOGE airdrops if I reply multiple times?"}
        content={"No, multiple mentions in one reply counts as one reply."}
      />
      <FaQ
        title={"Will reply bots take all the rewards?"}
        content={
          "Internal anti-bot algorithms will screen out bots without warning."
        }
      />
    </div>
  );
};

export default FaQContainer;
