import { useAuth0 } from "@auth0/auth0-react";
import { doCopy, getCopyText } from "../../util";
import CopyTextContainer from "./copy-text-container/CopyTextContainer";
import "./CopyReferralText.scss";

interface Props {
  proceedNextStep: () => void;
}

const CopyReferralText = ({ proceedNextStep }: Props) => {
  const { user } = useAuth0();
  const onClickSubmit = () => {
    doCopy(getCopyText(String(user?.sub?.split("|")[1])));
    proceedNextStep();
  };
  return (
    <div className="copy-referral-text airdrop-content">
      <span className="step">Step 03</span>
      <span className="title">Copy your referral text</span>
      <CopyTextContainer referralCode={String(user?.sub?.split("|")[1])} />
      <button className="submit-button" onClick={onClickSubmit}>
        Copy
      </button>
    </div>
  );
};

export default CopyReferralText;
