import "./MobilePreventPopup.scss";
import Modal from "react-modal";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

const customStyles = {
  content: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    width: "",
    zIndex: 100,
    // display: "flex",
  },
};

const MobilePreventPopup = ({ isModalOpen, setIsModalOpen }: Props) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
      className="mobile-prevent-popup"
    >
      <i className="close-icon" onClick={() => setIsModalOpen(false)} />
      <span className="title">
        You can claim
        <br /> only on the web :(
      </span>
      <span className="content">Please try again on web</span>
    </Modal>
  );
};

export default MobilePreventPopup;
