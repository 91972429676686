import "./CopyTextContainer.scss";

interface Props {
  needLineBreak?: boolean;
  referralCode?: string;
}

const CopyTextContainer = ({ needLineBreak, referralCode }: Props) => {
  return (
    <div className="copy-text-container">
      <span>
        Hey Elon, DOGE is fetching tweets from the sky!
        {needLineBreak && <br />}
        <span> @skydoge_io </span>
        {needLineBreak && <br />}
        <span>www.skydoge.io{referralCode && `/${referralCode}`}</span>
      </span>
    </div>
  );
};

export default CopyTextContainer;
