import BigNumber from "bignumber.js";
import toast from "react-simple-toasts";

export const doCopy = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Copied!", { className: "simple-toast" });
      })
      .catch(() => {
        toast("Something went wrong.", { className: "simple-toast" });
      });
  } else {
    if (!document.queryCommandSupported("copy")) {
      return alert("copy not allowed browser");
    }

    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast("Copied!", { className: "simple-toast" });
  }
};

export const getCopyText = (referralCode: string) => {
  return `Hey Elon, DOGE is fetching tweets from the sky! @skydoge_io www.skydoge.io/${referralCode}`;
};

export const numberWithCommas = (x: string | number | BigNumber) => {
  const xString = x.toString();
  const stringList = xString.split(".");

  return stringList[1]
    ? stringList[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "." +
        stringList[1]
    : stringList[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
