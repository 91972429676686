import "./BuyGuideScroll.scss";
import BuyGuideStep from "./BuyGuideStep";

const BuyGuideScroll = () => {
  const onClickMetamaskInstall = () => {
    if (window.innerWidth > 1080) {
      window.open("https://metamask.io/download/");
    } else {
      const userAgent = navigator.userAgent || navigator.vendor;

      if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.open("https://apps.apple.com/us/app/metamask/id1438144202");
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=io.metamask&hl=en_US"
        );
      }
    }
  };
  return (
    <div className="buy-guide-scroll">
      <BuyGuideStep
        icon="metamask"
        step={1}
        description="Install Metamask 
    for Chrome"
        buttonInfo={{
          title: "Install Metamask",
          onClick: onClickMetamaskInstall,
        }}
      />
      <BuyGuideStep
        icon="transfer"
        step={2}
        description="Transfer ETH to your Metamask from exchanges like Coinbase or Binance"
      />
      <BuyGuideStep
        icon="uniswap"
        step={3}
        description="Go to Uniswap, click ‘Connect wallet’ and select Metamask"
        buttonInfo={{
          title: "BUY on Uniswap",
          onClick: () =>
            window.open(
              "https://app.uniswap.org/#/swap?exactField=output&inputCurrency=ETH&outputCurrency=0x3c00f3E5A1cB06CcA9eC0fb8B36Af2DAaF54fBBb"
            ),
        }}
      />
      <BuyGuideStep
        icon="swap-doge"
        step={4}
        description="Swap $ETH for $SKYDOGE"
      />
    </div>
  );
};

export default BuyGuideScroll;
