import "./Loading.scss";

const Loading = () => {
  return (
    <div className="loading-three-point">
      <div className="first-circle" />
      <div className="second-circle" />
      <div className="third-circle" />
    </div>
  );
};

export default Loading;
