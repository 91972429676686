import { useWeb3React } from "@web3-react/core";
import { Contract } from "@ethersproject/contracts";
import { tokenAddress } from "../const";
import { tokenAbi } from "../abi/tokenAbi";
import { useCallback, useState } from "react";

export enum TrasnactionStatus {
  NONE = "None",
  SIGNING = "Signing",
  PENDING = "Pending",
  CONFIRM = "Confirm",
  ERROR = "Error",
}

export const useSignedTokenContract = () => {
  const { library } = useWeb3React();
  const contract = new Contract(tokenAddress, tokenAbi, library?.getSigner());
  return { contract };
};

export const useSend = (signedContract: Contract) => {
  const [status, setStatus] = useState<TrasnactionStatus>(
    TrasnactionStatus.NONE
  );
  const { library } = useWeb3React();
  const request = useCallback(
    async (functionName: string, args: string[]) => {
      setStatus(TrasnactionStatus.SIGNING);
      try {
        const response = await signedContract[functionName](...args);
        setStatus(TrasnactionStatus.PENDING);
        await library.getTransactionReceipt(response.hash);
        setStatus(TrasnactionStatus.CONFIRM);
      } catch (err) {
        setStatus(TrasnactionStatus.NONE);
      }
    },
    [signedContract]
  );
  return { request, status };
};

export const useClaim = () => {
  const { contract } = useSignedTokenContract();
  const { request, status } = useSend(contract);
  const claimRequest = (args: any[]) => {
    request("claim", args);
  };
  return { claimRequest, status };
};
