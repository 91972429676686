import { useState } from "react";
import Loading from "../../loading/Loading";
import "./EnterAddress.scss";

interface Props {
  register: (address: string) => Promise<void>;
}

const EnterAddress = ({ register }: Props) => {
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onChangeAddress = (e: any) => {
    setAddress(e.target.value);
  };
  const registerWithAddress = async () => {
    setIsLoading(true);
    await register(address);
    setIsLoading(false);
  };
  return (
    <div className="enter-address airdrop-content">
      <span className="step">Step 02</span>
      <span className="title">Enter address to get $SKYDOGE</span>
      <span className="wallet-address">Wallet Address</span>
      <input
        className="address-input"
        placeholder="ETH wallet address"
        value={address}
        onChange={onChangeAddress}
      />
      <button
        className={`submit-button ${isLoading ? "loading" : ""}`}
        onClick={registerWithAddress}
      >
        {isLoading ? <Loading /> : "Submit address"}
      </button>
    </div>
  );
};

export default EnterAddress;
