import { useState } from "react";
import "./FaQ.scss";

interface Props {
  title: string;
  content: string;
}

const FaQ = ({ title, content }: Props) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <div className="FaQ" onClick={() => setShowContent((content) => !content)}>
      <div className="upper-row">
        <span className="title">{title}</span>
        <i className={`arrow ${showContent && "show"}`} />
      </div>

      {showContent && <span className="content">{content}</span>}
    </div>
  );
};

export default FaQ;
