import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/fonts/fonts.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import ReplyEncourage from "./components/reply-encourage/ReplyEncourage";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  return library;
}
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  <>
    <Auth0Provider {...providerConfig}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ReplyEncourage />
        <App />
      </Web3ReactProvider>
    </Auth0Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
