import "./Footer.scss";

const Footer = () => {
  const downloadPdf = () => {
    fetch("terms.pdf").then((response) =>
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "terms.pdf";
        alink.click();
      })
    );
  };
  return (
    <div className="footer">
      <span onClick={downloadPdf}>SKYDOGE</span>
      <div className="icon-row">
        <div
          className="discord-icon"
          onClick={() => window.open("https://discord.gg/jSsEX57C2v")}
        />
        <div
          className="twitter-icon"
          onClick={() => window.open("https://twitter.com/skydoge_io")}
        />
      </div>
    </div>
  );
};

export default Footer;
