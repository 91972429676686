import { useEffect, useState } from "react";
import "./AirdropHeader.scss";

interface Props {
  index: number;
}

const AirdropHeader = ({ index }: Props) => {
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);

  useEffect(() => {
    setFrom(to);
    setTo(index);
  }, [index]);
  return (
    <div className="airdrop-header">
      <div className={`number-circle ${index === 1 && "active"}`}>1</div>
      <div
        className={`line 
      ${from === 1 && to === 2 && "lineProceed"}
      ${from === 2 && to === 1 && "lineGoBack"}
      `}
      />
      <div className={`number-circle ${index === 2 && "active"}`}>2</div>
      <div
        className={`line
      ${from === 2 && to === 3 && "lineProceed"}
      ${from === 3 && to === 2 && "lineGoBack"}
      `}
      />
      <div className={`number-circle ${index === 3 && "active"}`}>3</div>
      <div
        className={`line
      ${from === 3 && to === 4 && "lineProceed"}
      ${from === 4 && to === 3 && "lineGoBack"}
      `}
      />
      <div className={`number-circle ${index === 4 && "active"}`}>4</div>
      <div
        className={`line
      ${from === 4 && to === 5 && "lineProceed"}
      ${from === 5 && to === 4 && "lineGoBack"}`}
      />
      <div className={`number-circle ${index === 5 && "active"}`}>5</div>
    </div>
  );
};

export default AirdropHeader;
