import { doCopy, getCopyText } from "../../util";
import CopyTextContainer from "../copy-referral-text/copy-text-container/CopyTextContainer";
import "./AirDropClaim.scss";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../../connectors";
import { useEffect, useState } from "react";
import axios from "axios";
import { backendApiUrl, CHAIN_ID } from "../../../const";
import { useAuth0 } from "@auth0/auth0-react";
import Web3 from "web3";
import { TrasnactionStatus, useClaim } from "../../../hooks/useTokenContract";
import toast from "react-simple-toasts";

import MobilePreventPopup from "../../mobile-prevent-popup/MobilePreventPopup";

interface Props {
  userInfo: {
    claimable: number;
    referral: number;
    wallet?: string;
  };
}

interface ClaimInfo {
  walletAddress: string;
  claimable: string;
  deadline: string;
  signature: string;
}

const AirDropClaim = ({ userInfo: { referral } }: Props) => {
  const { active, activate, chainId, deactivate, library } = useWeb3React();
  const { user } = useAuth0();
  const [claimInfo, setClaimInfo] = useState<ClaimInfo>();
  const { claimRequest, status } = useClaim();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [restTime, setRestTime] = useState("00:00");
  const activateMetamask = async () => {
    await activate(injected, (error) => {
      if (error.name === "NoEthereumProviderError") {
        window.open("https://metamask.io/download.html");
      }
    });
  };
  const onClickConnectMetamask = async () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)
    ) {
      setIsModalOpen(true);
      return;
    }
    if (active) {
      deactivate();
      setClaimInfo(undefined);
    } else {
      activateMetamask();
      updateClaimInfo();
    }
  };

  const updateClaimInfo = async () => {
    try {
      const res = await axios.get(`${backendApiUrl}/claim/${user?.sub}`);
      if (res.data) {
        setClaimInfo(res.data);
      }
    } catch (err: any) {}
  };

  const activateChain = async () => {
    if (active && chainId !== CHAIN_ID) {
      try {
        await library.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
        });
      } catch (err: any) {
        if (err.code === 4902) {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.toHex(CHAIN_ID),
                chainName: "Ethereum",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                rpcUrls: ["https://mainnet.infura.io/v3/"],
              },
            ],
          });
        }
        library.provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(CHAIN_ID) }],
        });
      }
    }
  };

  const onClaim = () => {
    claimRequest([
      claimInfo?.walletAddress,
      claimInfo?.claimable,
      claimInfo?.deadline,
      claimInfo?.signature,
    ]);
  };

  useEffect(() => {
    activateMetamask();
    updateClaimInfo();
    setInterval(() => {
      const currentSecond = new Date().getUTCSeconds();
      const currentMinute = new Date().getUTCMinutes();
      setRestTime(
        `${String(
          (currentSecond === 0 ? 60 - currentMinute : 59 - currentMinute) % 5
        ).padStart(2, "0")}:${String(
          currentSecond === 0 ? 0 : 60 - currentSecond
        ).padStart(2, "0")}`
      );
      if (restTime === "00:00") {
        updateClaimInfo();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    activateChain();
  }, [active, chainId]);

  useEffect(() => {
    if (status === TrasnactionStatus.CONFIRM) {
      setClaimInfo(undefined);
      toast(
        `${BigNumber(claimInfo?.claimable.toString() ?? 0)
          .dividedBy(10 ** 8)
          .toFixed(2)} SKYDOGE claimed`,
        {
          className: "simple-toast",
        }
      );
    }
  }, [status]);
  return (
    <div className="airdrop-content air-drop-claim">
      <span className="step">Step 05</span>
      <span className="title">Get $SKYDOGE airdrop every 5 minutes. </span>
      <div className="info-container">
        <span className="title">$SKYDOGE to Earn</span>
        <span className="content">
          {BigNumber(claimInfo?.claimable.toString() ?? 0)
            .dividedBy(10 ** 8)
            .toFixed(4)}
        </span>
      </div>
      <div className="info-container">
        <span className="title">Total Referral</span>
        <span className="content">{referral}</span>
      </div>
      <button
        className="submit-button connect-wallet"
        onClick={onClickConnectMetamask}
      >
        {active ? "Disconnect" : "Connect wallet for claim"}
      </button>
      <button
        className={`submit-button claim ${
          (!claimInfo?.claimable || !active) && "disabled"
        }`}
        onClick={onClaim}
      >
        {claimInfo?.claimable ? "Claim" : `Claim ${restTime} mins later`}
      </button>
      <div className="copy-container">
        <span className="want-more">Want more $SKYDOGE?</span>
        <span className="more-replies">
          The more replies, the more airdrop!
        </span>
        <CopyTextContainer referralCode={user?.sub?.split("|")[1]} />
        <button
          className="submit-button"
          onClick={() => doCopy(getCopyText(user?.sub?.split("|")[1] ?? ""))}
        >
          Copy
        </button>
      </div>
      <MobilePreventPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default AirDropClaim;
