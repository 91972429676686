import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import AirdropHeader from "./airdrop-header/AirdropHeader";
import "./AirdropContainer.scss";
import axios from "axios";
import { backendApiUrl } from "../../const";
import ConnectTwitter from "./connect-twitter/ConnectTwitter";
import EnterAddress from "./enter-address/EnterAddress";
import { getConfig } from "../../config";
import CopyReferralText from "./copy-referral-text/CopyReferralText";
import PasteReferralText from "./paste-referral-text/PasteReferralText";
import AirDropClaim from "./airdrop-claim/AirDropClaim";
import toast from "react-simple-toasts";

const AirdropContainer = () => {
  const { user, getAccessTokenWithPopup, logout } = useAuth0();
  const [index, setIndex] = useState(1);
  const [userInfo, setUserInfo] = useState<{
    claimable: number;
    referral: number;
    wallet?: string;
  }>({
    claimable: 0,
    referral: 0,
    wallet: undefined,
  });

  const getReferral = () => {
    const storageReferral = localStorage.getItem("referral");
    if (
      window.location.pathname?.split("/")[1] &&
      !isNaN(Number(window.location.pathname?.split("/")[1]))
    ) {
      return window.location.pathname.split("/")[1];
    } else if (storageReferral) {
      return storageReferral;
    }
    return "";
  };

  const registerApi = async (walletAddress: string) => {
    try {
      const authToken = await getAccessTokenWithPopup({
        audience: `https://${getConfig().domain}/api/v2/`,
        scope: "read:current_user",
      });
      await axios.post(`${backendApiUrl}/register`, {
        authToken,
        userId: user?.sub,
        walletAddress,
        referral: getReferral(),
      });
      setUserInfo({
        ...userInfo,
        wallet: walletAddress,
      });
      setIndex(3);
    } catch (err) {
      toast("Something went wrong.", { className: "simple-toast" });
      console.log(err, "error");
    }
  };
  const getUserInfo = async () => {
    try {
      const userInfo = await axios.get(
        `${backendApiUrl}/userInfo/${user?.sub}`
      );
      if (userInfo.data.wallet) {
        setUserInfo(userInfo.data);
        setIndex(5);
      }
    } catch {}
  };
  useEffect(() => {
    getReferral();
    if (user?.sub) {
      toast("Connected!", { className: "simple-toast" });
      setIndex(2);
      getUserInfo();
    }
  }, [user?.sub]);

  useEffect(() => {
    if (
      window.location.pathname?.split("/")[1] &&
      !isNaN(Number(window.location.pathname?.split("/")[1]))
    ) {
      localStorage.setItem("referral", window.location.pathname.split("/")[1]);
    }
  }, [window.location.pathname]);

  return (
    <div className="airdrop-container">
      <AirdropHeader index={index} />
      {index === 1 && <ConnectTwitter />}
      {index === 2 && <EnterAddress register={registerApi} />}
      {index === 3 && (
        <CopyReferralText
          proceedNextStep={() => {
            setIndex(4);
          }}
        />
      )}
      {index === 4 && (
        <PasteReferralText
          proceedNextStep={() => {
            setIndex(5);
          }}
        />
      )}
      {index === 5 && <AirDropClaim userInfo={userInfo} />}
    </div>
  );
};

export default AirdropContainer;
