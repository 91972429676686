import { useAuth0 } from "@auth0/auth0-react";
import { doCopy, getCopyText } from "../util";
import "./ReplyEncourage.scss";

const ReplyEncourage = () => {
  const { user } = useAuth0();
  return (
    <div className="reply-encourage">
      <span className="title">
        THE MORE REPLIES <br /> THE MORE AIRDROPS
      </span>
      <div className="copy-container">
        <div className="copy-text-container">
          <span>
            Hey Elon, DOGE is fetching tweets from the sky!
            <br />
            <span>@skydoge_io</span>
            <br />
            <span>
              www.skydoge.io{user?.sub ? `/${user.sub.split("|")[1]}` : ""}
            </span>
          </span>
        </div>
        <button
          className="copy-button"
          onClick={() =>
            doCopy(getCopyText(user?.sub ? user.sub.split("|")[1] : ""))
          }
        >
          Copy Text
        </button>
        <span
          className="go-to-elon"
          onClick={() => window.open("https://www.twitter.com/elonmusk")}
        >
          Go To Elon's Account
        </span>
      </div>
    </div>
  );
};

export default ReplyEncourage;
